@import "fonts";
@import "variables";
@import "~bulma/bulma";
@import "~bulma-steps-component/bulma-steps";
@import "~bulma-extensions/bulma-timeline/src/sass";
@import "~bulma-extensions/bulma-divider/src/sass";
@import "~bulma-extensions/bulma-switch/src/sass";
@import "transitions";
@import "~react-datepicker/dist/react-datepicker.css";

html, body {
  width: 100%;
  height: 100%;
}

.nav-hero {
  background-color: $background;

  figure img {
    border-radius: $radius-large;
    object-fit: contain;
    max-height: 94px;
  }
}

.topic-item {
  border-left: 0.3rem solid lighten($primary, 50%);

  &:hover {
    border-left-color: $primary;
  }
}

.board-list {
  .subtitle {
    hyphens: auto;
  }
}

.board-item {
  cursor: pointer;
  hyphens: auto;
  padding: 0.8rem;
  border-left: 0.3rem solid lighten($primary, 50%);

  &:hover {
    border-left-color: $primary;
  }

  &.is-dropzone {
    border: 1px dashed $grey;
    text-align: center;
    color: $grey-dark;
    background-color: $light;
  }
}

.modal {
  .modal-card {
    @include desktop {
      position: absolute;
      top: 0;
      right: 0;
    }
    min-height: 100vh;

    .modal-card-title {
      @include mobile {
        max-width: calc(100% - 20px);
      }
    }
  }

  .modal-actions {
    position: absolute;
    top: 0;
    right: 70px;
    display: flex;
    align-items: center;;
    justify-content: center;

    & > div {
      background-color: $grey-lighter;
      margin-left: $size-7;
      padding: $size-7;
      padding-top: 18px;
      border-bottom-left-radius: $radius;
      border-bottom-right-radius: $radius;
    }
  }
}

.has-background-highlight {
  background-color: $highlight;
}

.uploads {
  &.is-boxes .upload {
    word-break: break-word;

    position: relative;
    border: 1px solid $grey-lightest;
    border-radius: $radius;
    margin: 0.2rem;

    &:hover {
      border-color: $grey-light;

      .upload-context-menu {
        display: block;
      }
    }

    .upload-context-menu {
      position: absolute;
      display: none;
      right: 0.4rem;
      top: 0.4rem;
      z-index: 5000;
    }

    figure.image {
      &.is-cover {
        img {
          border: 3px solid $primary;
          border-radius: $radius;
        }
      }

      img {
        object-fit: cover;
        border-radius: $radius;
      }
    }

    svg {
      max-width: 48px;
      max-height: 48px;
    }
  }

  &.is-list .upload {
    figure.image {
      width: 32px;
      height: 32px;

      &.is-cover {
        img {
          border: 3px solid $primary;
          border-radius: $radius;
        }
      }

      img {
        border: 3px solid transparent;
        object-fit: cover;
        border-radius: $radius;
      }
    }

    svg {
      width: 32px;
      height: 32px;
    }
  }
}

table {
  th.is-not-wrapped,
  td.is-not-wrapped {
    white-space: nowrap;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.content {
  li + li {
    margin-top: 0;
  }

  p, dl, ol, ul, blockquote, pre, table {
    &:not(:last-child) {
      margin-bottom: .5em;
    }
  }

  ul {
    margin-top: 0;

    ul {
      margin-top: 0;
      margin-bottom: 0.25em;
    }

    li input[type="checkbox"] {
      margin-right: 0.4rem;
    }
  }
}

.field.is-required {
  label:after {
    content: " *";
    color: $primary;
  }
}

#layout {
  display: flex;
  height: 100vh;

  @include mobile {
    flex-direction: column;
  }

  aside {
    @include mobile {
      display: none;
    }

    width: $left-panel-width;
    flex-shrink: 0;

    #user {
      display: flex;
      align-items: center;
      line-height: $header-height;
      padding: 0 calc(1.5rem - 10px);
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;

      background: $primary;
      color: $white;

      img {
        margin-right: 1em;
      }

      span {
        font-size: $size-4;
      }
    }

    #menu {
      padding: 1rem 0;

      & > a {
        display: flex;
        align-items: center;
        padding: 1rem 1.5rem;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;

        font-weight: bold;
        color: $text;

        svg {
          margin-right: 1em;
        }

        &.is-active, &:hover {
          border-left-color: $primary;
          background-color: $grey-lighter;
        }
      }
    }
  }

  nav#desktop_nav {
    @include tablet {
      display: none;
    }

    background: $grey-lighter;
  }

  main {
    flex: 1;

    #header {
      .select {
        @include tablet {
          display: none;
        }
      }
      
      .level {
        line-height: $header-height;
        padding: 0 $size-3;
        font-size: $size-5;
        
        @include mobile {
          display: none;
        }

        .level-menu {
          a {
            color: $text;
            padding-right: 1rem;

            &.is-active {
              color: $primary;
              font-weight: bold;
            }
          }
        }
      }
    }

    #content {
      flex: 1;
      height: calc(100vh - #{$header-height});

      background: $grey-lighter;
      box-shadow: rgba(150, 150, 150, .15) inset .5rem .5rem .5rem;
      padding: $size-3;
      overflow: auto;

      @include mobile {
        padding: $size-7;
      }
    }
  }
}

#login {
  .is-fullheight-with-navbar {
    min-height: calc(100vh - 3.25rem);
  }

  .has-text-white a {
    color: $white;
    text-decoration: underline;
  }
}

#text_logo {
  .inner {
    background-color: $logo;
    border-radius: 1em;
    padding: 0.4em 1em;
    font-size: 1em;
    color: $white;
    letter-spacing: .2em;
    font-weight: normal;
    box-shadow: 0 0.5rem 0 #14253c;

    strong {
      color: inherit;
    }
  }
}

.buttons > * {
  &:not(:first-child) {
    &.button.is-rounded, .button.is-rounded {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &:not(:last-child) {
    &.button.is-rounded, .button.is-rounded {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.definition-list {
  display: flex;
  flex-wrap: wrap;

  dt {
    padding-right: 2em;
    font-weight: bold;
    color: $grey;
    flex-basis: 30%;
    text-align: right;
  }

  dd {
    flex-basis: 70%;
  }
}

.is-clickable {
  cursor: pointer !important;
  pointer-events: initial !important;
}

.is-not-clickable {
  cursor: default !important;
}

table.grundansprueche-table {
  th {
    vertical-align: bottom;
  }
}

th.rotate {
  vertical-align: bottom;
  text-align: center !important;

  span {
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    white-space: nowrap;
  }
}