$background: #fff;

//$primary: #e2a301; // https://colorpalettes.net/color-palette-2463/
//$primary: #a1ae25;
//$primary: #224871;
//$primary: #4886af;
//$primary: #153f65;
//$primary: #628ca6;
$primary: #a27eb8; // https://colorpalettes.net/color-palette-1519/

$link: $primary;
$grey-lighter: #f5f5f5;

$tabs-color: hsl(0, 0%, 56%);
$tabs-boxed-link-active-border-color: $tabs-color;
$tabs-border-bottom-color: $tabs-color;
$tabs-link-active-color: $tabs-color;
$tabs-boxed-link-hover-border-bottom-color: $tabs-color;

$logo: #1d3557;
$highlight: darken($grey-lighter, 5%);

$timeline-marker-icon-size: 2em;

$radius-large: .4rem;
$box-padding: .8rem;

$modal-card-head-radius: 0;
$modal-content-width: 1000px;
$modal-background-background-color: rgba(150, 150, 150, .2);
$modal-card-head-background-color: $background;

$family-primary: 'Roboto';
$family-headings: 'Roboto';

$header-height: 5rem;
$left-panel-width: 15rem;